import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
          <path d="M3718 6557 c-145 -41 -219 -166 -205 -350 16 -196 129 -290 352 -290
134 0 207 23 272 88 59 60 73 95 80 213 11 161 -44 272 -159 323 -44 19 -73
24 -173 26 -77 2 -137 -2 -167 -10z m242 -119 c57 -29 87 -74 95 -143 22 -176
-60 -278 -210 -262 -110 11 -156 59 -163 172 -6 102 4 156 36 194 52 61 163
79 242 39z"/>
<path d="M6605 6561 c-90 -22 -162 -84 -194 -167 -42 -109 -23 -299 37 -369
62 -73 154 -107 290 -108 221 0 328 80 355 268 9 60 8 87 -5 149 -27 123 -91
194 -200 223 -55 14 -232 16 -283 4z m251 -132 c52 -32 74 -85 74 -184 0 -98
-22 -152 -73 -183 -74 -46 -198 -37 -255 19 -61 60 -69 248 -14 315 54 65 189
82 268 33z"/>
<path d="M4309 6539 c-8 -15 -10 -86 -7 -233 4 -231 8 -247 71 -313 48 -51
112 -68 257 -68 106 0 132 3 170 21 62 29 108 79 125 138 19 64 22 445 3 464
-7 7 -37 12 -68 12 -31 0 -61 -5 -68 -12 -9 -9 -12 -68 -12 -205 0 -277 -8
-293 -155 -293 -85 0 -97 2 -122 24 l-28 24 -5 219 c-3 120 -9 224 -13 231 -5
7 -32 12 -72 12 -55 0 -66 -3 -76 -21z"/>
<path d="M5073 6549 c-10 -10 -13 -84 -13 -300 0 -158 3 -294 6 -303 8 -20
123 -23 142 -4 8 8 12 48 12 116 l0 104 79 -3 c95 -5 105 -12 187 -135 29 -45
61 -85 70 -88 8 -3 47 -6 85 -6 54 0 69 3 69 15 0 21 -87 158 -132 210 l-39
44 50 21 c80 35 96 59 96 144 0 64 -3 77 -28 109 -16 21 -47 47 -70 60 -39 21
-56 22 -272 25 -175 2 -233 0 -242 -9z m390 -120 c73 -20 87 -91 24 -129 -27
-17 -50 -20 -149 -20 l-118 0 0 73 c0 41 3 77 7 80 10 11 194 8 236 -4z"/>
<path d="M5732 6548 c-7 -7 -12 -28 -12 -48 0 -55 11 -60 132 -60 l108 0 0
-244 c0 -198 3 -246 14 -255 9 -7 40 -11 78 -9 l63 3 5 250 5 250 115 5 115 5
3 34 c2 19 1 44 -2 57 l-6 24 -303 0 c-222 0 -306 -3 -315 -12z"/>
<path d="M7191 6541 c-15 -26 -15 -565 -1 -592 9 -16 22 -19 75 -19 76 0 85
10 85 89 l0 51 138 0 c167 0 226 14 277 67 87 89 83 278 -7 359 -60 56 -77 59
-325 62 -223 3 -232 3 -242 -17z m384 -111 c57 -13 85 -49 85 -110 0 -106 -25
-123 -187 -128 l-123 -4 0 126 0 126 93 0 c50 0 110 -5 132 -10z"/>
<path d="M7912 6543 c-14 -34 -8 -425 7 -467 21 -59 74 -112 134 -133 79 -27
285 -26 347 3 64 30 107 76 125 136 19 66 22 447 3 466 -7 7 -37 12 -68 12
-31 0 -61 -5 -68 -12 -9 -9 -12 -68 -12 -205 0 -277 -8 -293 -155 -293 -85 0
-97 2 -122 24 l-28 24 -5 219 c-3 120 -9 224 -13 231 -5 7 -32 12 -73 12 -52
0 -66 -3 -72 -17z"/>
<path d="M8672 6548 c-9 -9 -12 -90 -12 -303 0 -213 3 -294 12 -303 16 -16
123 -16 136 1 6 7 12 37 14 67 l3 55 160 5 c138 5 166 8 200 27 80 42 115 108
115 216 0 82 -18 135 -60 177 -60 60 -111 70 -349 70 -148 0 -210 -3 -219 -12z
m393 -121 c96 -29 100 -192 5 -224 -19 -7 -83 -12 -142 -12 l-108 -1 0 118 c0
65 3 122 7 125 11 12 195 6 238 -6z"/>
<path d="M4250 5590 c-13 -13 -20 -33 -20 -57 0 -45 4 -51 53 -87 28 -21 37
-36 37 -57 0 -22 -5 -29 -20 -29 -16 0 -20 7 -20 30 0 27 -3 30 -30 30 -28 0
-30 -2 -30 -39 0 -21 6 -43 13 -48 38 -31 127 -20 147 17 13 24 13 76 0 100
-5 10 -26 28 -45 40 -27 17 -35 28 -35 51 0 35 27 40 32 7 2 -18 10 -23 36
-25 29 -1 32 1 32 27 0 38 -32 60 -87 60 -30 0 -49 -6 -63 -20z"/>
<path d="M4427 5604 c-15 -16 -6 -43 16 -46 12 -2 21 -8 21 -13 -1 -6 -5 -58
-9 -117 l-7 -108 35 0 35 0 7 109 c8 118 10 127 40 132 12 3 20 12 20 24 0 18
-8 20 -75 23 -42 1 -79 0 -83 -4z"/>
<path d="M4616 5588 c-6 -25 -31 -136 -46 -205 -14 -61 -13 -63 25 -63 27 0
35 4 35 18 0 10 5 23 11 29 17 17 39 4 39 -24 0 -21 4 -24 33 -21 20 2 32 8
32 18 0 8 -4 72 -9 143 l-8 127 -54 0 c-48 0 -54 -3 -58 -22z m64 -108 c0 -36
-4 -52 -15 -57 -23 -8 -24 -3 -13 55 13 66 28 67 28 2z"/>
<path d="M4754 5593 c24 -79 36 -148 36 -205 l0 -68 29 0 c27 0 29 2 36 58 4
31 20 93 36 136 16 44 29 83 29 88 0 13 -50 9 -55 -4 -2 -7 -9 -32 -15 -55
-12 -46 -30 -58 -30 -19 0 13 -3 38 -6 55 -6 26 -11 31 -36 31 -23 0 -28 -4
-24 -17z"/>
<path d="M5061 5591 c-11 -11 -22 -35 -25 -53 -12 -77 -14 -162 -4 -184 16
-34 61 -49 106 -36 40 12 56 31 67 80 7 32 6 32 -34 32 -39 0 -40 -1 -43 -37
-2 -21 -7 -37 -13 -35 -6 1 -10 44 -10 104 0 90 2 103 18 106 14 3 17 -4 17
-37 0 -40 1 -41 35 -41 35 0 35 0 35 44 0 35 -5 48 -22 60 -32 23 -103 21
-127 -3z"/>
<path d="M5280 5592 c-19 -16 -25 -34 -33 -115 -12 -107 -5 -136 40 -156 40
-18 73 -13 104 18 26 26 29 37 31 98 3 113 0 137 -23 156 -28 22 -90 22 -119
-1z m70 -96 c0 -89 -9 -138 -24 -133 -11 4 -10 182 1 200 14 23 23 -2 23 -67z"/>
<path d="M5467 5603 c-3 -5 -8 -70 -12 -145 l-7 -138 30 0 c25 0 30 5 36 31 3
17 6 48 6 68 l1 36 14 -33 c8 -18 17 -48 21 -67 5 -30 10 -35 35 -35 15 0 30
3 32 8 3 4 8 69 12 145 l7 137 -30 0 c-30 0 -31 -1 -38 -66 -7 -72 -14 -70
-35 14 -12 47 -15 52 -40 52 -15 0 -30 -3 -32 -7z"/>
<path d="M5677 5504 c-12 -184 -12 -184 23 -184 29 0 30 1 30 48 0 78 16 84
35 14 16 -58 20 -62 47 -62 16 0 30 6 33 13 2 6 6 72 8 145 l6 133 -32 -3
c-31 -3 -32 -4 -37 -63 -6 -64 -11 -61 -35 17 -10 37 -16 43 -42 46 l-30 3 -6
-107z"/>
<path d="M5897 5603 c-4 -3 -7 -69 -7 -145 l0 -138 65 0 c63 0 65 1 65 25 0
21 -5 25 -31 25 -29 0 -30 2 -27 38 2 30 7 38 26 40 29 4 31 42 2 42 -16 0
-20 7 -20 35 0 31 3 35 25 35 20 0 25 5 25 25 0 24 -3 25 -58 25 -32 0 -62 -3
-65 -7z"/>
<path d="M6082 5590 c-14 -16 -21 -42 -25 -108 -8 -104 1 -143 34 -159 49 -25
124 -3 134 40 13 60 12 62 -27 65 -38 3 -38 3 -38 -33 0 -29 -3 -36 -17 -33
-10 2 -18 11 -17 21 0 9 1 55 2 102 2 65 5 85 16 85 18 0 27 -26 20 -56 -6
-22 -4 -24 35 -24 37 0 41 3 41 24 0 31 -18 78 -33 88 -7 4 -33 8 -59 8 -35 0
-52 -5 -66 -20z"/>
<path d="M6264 5595 c-8 -20 4 -35 27 -35 18 0 19 -8 19 -120 l0 -120 35 0 35
0 0 120 0 120 25 0 c20 0 25 5 25 25 0 25 -1 25 -80 25 -61 0 -82 -3 -86 -15z"/>
<path d="M6456 5558 c-3 -29 -6 -95 -6 -145 l0 -93 71 0 c67 0 70 1 67 22 -2
17 -12 24 -33 28 -23 4 -30 11 -31 30 -2 31 12 50 37 50 12 0 19 7 19 20 0 15
-7 20 -25 20 -22 0 -25 4 -25 35 0 31 3 35 25 35 20 0 25 5 25 25 0 24 -3 25
-59 25 l-58 0 -7 -52z"/>
<path d="M6618 5486 c-1 -69 -2 -134 -2 -143 -1 -15 10 -18 73 -21 106 -4 111
2 111 137 -1 138 -5 144 -105 149 l-75 4 -2 -126z m100 -27 c-4 -119 -22 -121
-26 -2 -3 93 2 123 19 106 5 -5 9 -50 7 -104z"/>
<path d="M7000 5465 l0 -145 78 0 c47 0 83 5 90 12 7 7 12 36 12 65 0 44 -4
54 -22 64 -21 11 -21 12 -3 25 24 18 24 78 -1 105 -14 15 -31 19 -86 19 l-68
0 0 -145z m105 64 c0 -24 -5 -34 -15 -34 -16 0 -25 37 -16 62 10 26 31 8 31
-28z m3 -121 c-2 -30 -8 -43 -18 -43 -10 0 -16 13 -18 43 -3 37 -1 42 18 42
19 0 21 -5 18 -42z"/>
<path d="M7220 5465 l0 -145 70 0 c68 0 70 1 70 24 0 21 -6 25 -32 28 -28 3
-33 7 -34 31 -1 26 15 44 41 46 6 0 11 9 13 20 2 15 -3 20 -25 23 -24 3 -28 8
-28 33 0 25 4 30 28 33 21 3 27 9 27 28 0 23 -3 24 -65 24 l-65 0 0 -145z"/>
<path d="M7496 5573 c-3 -21 -10 -76 -16 -123 -6 -47 -13 -95 -15 -107 -5 -21
-2 -23 35 -23 36 0 40 2 40 25 0 33 37 35 42 3 2 -19 10 -24 41 -26 38 -3 39
-2 33 25 -3 15 -15 81 -27 146 l-21 117 -53 0 -53 0 -6 -37z m73 -93 c7 -41 5
-53 -6 -57 -22 -9 -25 2 -18 62 4 31 9 54 12 52 2 -3 8 -28 12 -57z"/>
<path d="M7700 5588 c-17 -18 -20 -35 -20 -108 0 -48 5 -101 10 -117 26 -74
158 -68 174 8 11 56 9 59 -34 59 -39 0 -40 -1 -40 -35 0 -23 -5 -35 -14 -35
-10 0 -15 24 -21 95 -7 97 -4 115 21 115 10 0 14 -12 14 -40 0 -39 1 -40 35
-40 34 0 35 1 35 40 0 57 -26 80 -90 80 -38 0 -54 -5 -70 -22z"/>
<path d="M7882 5588 c2 -15 11 -24 24 -26 20 -3 22 -11 29 -123 l7 -119 34 0
34 0 0 120 c0 113 1 120 20 120 15 0 20 7 20 25 0 25 -1 25 -86 25 -83 0 -85
-1 -82 -22z"/>
<path d="M8083 5513 c4 -54 7 -119 7 -145 l0 -48 41 0 c38 0 41 2 35 23 -3 12
-6 77 -6 145 l0 122 -42 0 -41 0 6 -97z"/>
<path d="M8180 5592 c0 -11 11 -73 25 -138 14 -65 25 -122 25 -126 0 -4 26 -8
59 -8 69 0 66 -7 77 174 l7 116 -35 0 c-34 0 -35 -1 -42 -46 -3 -26 -6 -72 -6
-102 0 -80 -10 -58 -24 50 l-11 93 -38 3 c-31 3 -37 0 -37 -16z"/>
<path d="M8397 5508 c2 -57 6 -122 9 -145 l5 -43 65 0 c62 0 64 1 64 25 0 21
-5 25 -30 25 -28 0 -30 3 -30 35 0 19 5 36 10 36 6 1 16 2 23 3 6 0 13 11 15
24 3 18 -1 22 -22 22 -23 0 -26 4 -26 35 0 27 4 35 18 35 25 0 31 7 25 31 -5
16 -14 19 -68 19 l-62 0 4 -102z"/>
<path d="M6832 5325 c1 -25 3 -45 5 -45 12 0 43 49 43 67 0 18 -6 23 -25 23
-23 0 -24 -3 -23 -45z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
